// extracted by mini-css-extract-plugin
export var mvpAppDevBenefitsSection = "C_dX";
export var mvpAppDevCaseSection = "C_d4";
export var mvpAppDevChoiceSection = "C_d2";
export var mvpAppDevClientQuotes = "C_d5";
export var mvpAppDevCostSection = "C_d0";
export var mvpAppDevExpertiseSection = "C_d3";
export var mvpAppDevIndustriesSection = "C_dV";
export var mvpAppDevPracticesSection = "C_d1";
export var mvpAppDevPrimeSection = "C_dS";
export var mvpAppDevProcessSection = "C_dY";
export var mvpAppDevServicesSection = "C_dT";
export var mvpAppDevTechSection = "C_dZ";
export var mvpAppDevTypesSection = "C_dW";